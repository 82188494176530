/* eslint-disable no-underscore-dangle */
import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { API, Auth } from 'aws-amplify';
import { Select, Spin } from 'antd';
import AllCountries from 'react-intl-tel-input/dist/components/AllCountries';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import dayjs from 'dayjs';
import * as styles from '../../styles/admin/UpdateCompanyProfile.module.scss';
import { updateEnterpriseProfile, updateUser } from '../../graphql/mutations';
import { getCurrentUser } from '../Shared/Services/AuthService';
import { handleBrokenImage, uploadCompanyLogo, fetchEnterpriseLogo } from './Services/CompanyProfileService';
import AdminContext, { EnterpriseInfo, ProfileInfo } from '../../context/Admin/AdminContext';
import { isBDMUser, postEvent, toaster } from '../../services/utils';
import { Role } from '../../models';
import { syncCustomerData } from './Services/ChargebeeService';

type companyPayload = {
  id: string,
  _version: number,
  name?: string,
  numberOfEmployees?: string,
  location?: string
}

type UserPayload = {
  id: string,
  _version: number,
  designation?: string,
  phoneNumber?: string
}

const { Option } = Select;

const CompanyProfile: React.FC = () => {
  const {
    profileInfo, companyInfo, setCompanyInfo, setProfileInfo,
  } = useContext(AdminContext);
  const [updatedUserInfo, setUpdatedUserInfo] = useState<ProfileInfo>({} as ProfileInfo);
  const [updatedCompanyInfo, setUpdatedCompanyInfo] = useState<
    EnterpriseInfo>({} as EnterpriseInfo);
  const [loader, setLoader] = useState({
    profile: false,
    name: false,
  });
  const allCountries = AllCountries.getCountries();
  const [errors, setErrors] = useState({
    name: false,
    phoneNumber: false,
    designation: false,
  });
  const [isEdit, setEdit] = useState({
    profile: false,
    name: false,
  });
  const [isNumberValid, setNumberValidity] = useState(false);
  const [enterpriseImage, setEnterpriseImage] = useState('');
  const [profilePicLoader, setProfilePicLoader] = useState(false);

  const validate = (name: string, value: string) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: !value,
    }));
  };

  const phoneNumberChanged = (isValid: string, number: string) => {
    if (isValid) {
      let phoneNumber = '';
      phoneNumber = parsePhoneNumberWithError(number).format('E.164');
      setNumberValidity(!!phoneNumber);
      setUpdatedUserInfo((prevState: ProfileInfo) => ({
        ...prevState,
        phoneNumber,
      }));
    } else {
      setNumberValidity(false);
    }
    validate('phoneNumber', isValid);
  };

  const changeCompanySize = (value: string) => {
    setUpdatedCompanyInfo({
      ...updatedCompanyInfo,
      numberOfEmployees: value,
    });
  };

  const changeCountry = (value: string) => {
    setUpdatedCompanyInfo({
      ...updatedCompanyInfo,
      location: value,
    });
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e?.target;
    if (name === 'company') {
      setUpdatedCompanyInfo((prevState) => ({
        ...prevState,
        name: value,
      }));
    } else {
      setUpdatedUserInfo((prevState: ProfileInfo) => ({
        ...prevState,
        [name]: value,
      }));
    }
    validate(name, value);
  };

  const updateCompanyInfo = async () => {
    let shouldUpdate = false;
    const payload = {
      id: companyInfo?.id,
      // eslint-disable-next-line no-underscore-dangle
      _version: companyInfo?._version,
    } as companyPayload;
    if (companyInfo?.name !== updatedCompanyInfo?.name) {
      if (!updatedCompanyInfo?.name) {
        toaster('Please enter the company name', 'error');
        return;
      }
      payload.name = updatedCompanyInfo?.name;
      shouldUpdate = true;
    }
    if (updatedCompanyInfo?.numberOfEmployees
      && companyInfo?.numberOfEmployees !== updatedCompanyInfo?.numberOfEmployees) {
      payload.numberOfEmployees = updatedCompanyInfo?.numberOfEmployees;
      shouldUpdate = true;
    }
    if (updatedCompanyInfo?.location && companyInfo?.location !== updatedCompanyInfo?.location) {
      payload.location = updatedCompanyInfo?.location;
      shouldUpdate = true;
    }
    if (shouldUpdate) {
      const updateResponse:any = await API.graphql({
        query: updateEnterpriseProfile,
        variables: {
          input: payload,
        },
      });

      const updatedVersion = updateResponse.data.updateEnterpriseProfile._version;
      setCompanyInfo({
        ...updatedCompanyInfo,
        _version: updatedVersion,
      });
    }

    let phone;
    if (updatedUserInfo?.phoneNumber && profileInfo?.phoneNumber !== updatedUserInfo?.phoneNumber) {
      phone = updatedUserInfo.phoneNumber;
    }
    const company = payload?.name;
    if (company || phone) {
      syncCustomerData({
        ...(phone && { phone }),
        ...(company && { company }),
      }, companyInfo?.id);
    }
  };

  const triggerIdentifyEvent = async (payload: UserPayload) => {
    const phone = payload?.phoneNumber;
    const title = payload?.designation;
    const eventPayload = {
      userId: profileInfo?.id,
      enterpriseId: profileInfo?.enterpriseID,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      traits: {
        email: profileInfo?.email,
        firstName: profileInfo?.firstName,
        lastName: profileInfo?.lastName,
        name: profileInfo?.name,
        roles: profileInfo?.roles,
        ...(phone && { phone }),
        ...(title && { title }),
        createdAt: profileInfo?.createdAt,
        lastActive: dayjs().toISOString(),
        exclude: isBDMUser(profileInfo?.email),
      },
    };
    postEvent(eventPayload, '/identify');
  };

  const updateUserInfo = async () => {
    let shouldUpdate = false;
    const payload = {
      id: profileInfo?.id,
      _version: profileInfo?._version,
    } as UserPayload;
    if (profileInfo?.designation !== updatedUserInfo?.designation) {
      payload.designation = updatedUserInfo.designation;
      shouldUpdate = true;
    }
    if (updatedUserInfo?.phoneNumber && profileInfo?.phoneNumber !== updatedUserInfo?.phoneNumber) {
      payload.phoneNumber = updatedUserInfo.phoneNumber;
      const user = await getCurrentUser();
      await Auth.updateUserAttributes(user, {
        phone_number: updatedUserInfo.phoneNumber,
      });
      shouldUpdate = true;
    }
    if (shouldUpdate) {
      const updateResponse:any = await API.graphql({
        query: updateUser,
        variables: {
          input: payload,
        },
      });

      const updatedVersion = updateResponse.data.updateUser._version;
      setProfileInfo({
        ...updatedUserInfo,
        _version: updatedVersion,
      });

      triggerIdentifyEvent(payload);
    }
  };

  const toggleEdit = (key: string, value: boolean, cancelEdit?: boolean) => {
    setEdit({
      ...isEdit,
      [key]: value,
    });
    if (cancelEdit) {
      setUpdatedCompanyInfo(companyInfo);
      setUpdatedUserInfo(profileInfo);
    }
  };

  const handleLoader = (key: string, value: boolean) => {
    setLoader({
      ...loader,
      [key]: value,
    });
  };

  const updateInfo = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (updatedUserInfo?.phoneNumber && !isNumberValid) {
      toaster('Invalid phone number', 'error');
      return;
    }
    handleLoader('profile', true);
    try {
      await updateCompanyInfo();
      await updateUserInfo();
    } catch (error) {
      toaster('Failed to update', 'error');
    } finally {
      handleLoader('profile', false);
      toggleEdit('profile', false);
    }
  };

  const updateCompanyName = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (updatedCompanyInfo?.name) {
      handleLoader('name', true);
      await updateCompanyInfo();

      const payload = {
        event: 'Account Updated',
        userId: profileInfo?.id,
        enterpriseId: companyInfo?.id,
        originalTimestamp: dayjs().toISOString(),
        sentAt: dayjs().toISOString(),
        properties: {
          accountName: updatedCompanyInfo?.name,
        },
      };
      postEvent(payload);
      handleLoader('name', false);
      toggleEdit('name', false);
    }
  };

  const uploadImage: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    setProfilePicLoader(true);
    try {
      const url = await uploadCompanyLogo(e, companyInfo);
      setEnterpriseImage(url);
    } catch (err: any) {
      toaster(err.message, 'error');
    }
    setProfilePicLoader(false);
  };

  const setEnterpriseLogo = async (companyData: EnterpriseInfo) => {
    try {
      if (companyData) {
        const url = await fetchEnterpriseLogo(companyData);
        if (url) {
          setEnterpriseImage(url);
        } else {
          setEnterpriseImage('/images/learner/avatar.png');
        }
      }
    } catch (error: any) {
      setEnterpriseImage('/images/learner/avatar.png');
    }
  };

  useEffect(() => {
    if (profileInfo) {
      setUpdatedUserInfo(profileInfo);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (companyInfo) {
      setUpdatedCompanyInfo(companyInfo);
      setEnterpriseLogo(companyInfo);
    }
  }, [companyInfo]);

  const checkOwnerRole = (roles: Role[] | string) => {
    if (!roles?.length) return false;
    const ownerRole = Role.OWNER;
    return roles.includes(ownerRole);
  };

  let currentCountry = updatedCompanyInfo?.location;
  if (currentCountry) {
    const filtered = allCountries.find((obj) => obj.iso2 === currentCountry);
    if (filtered) {
      currentCountry = filtered.name;
    }
  }

  const isOwner = useMemo(() => {
    if (profileInfo?.id) {
      return checkOwnerRole(profileInfo?.roles);
    }
    return false;
  }, [profileInfo]);

  return (
    <div className={styles.profileUpdateContainer}>
      <form className={styles.companyWrapper} onSubmit={updateCompanyName}>
        <div className={styles.userProfile}>
          {profilePicLoader
            && <Spin className={styles.profilePicLoader} />}
          <div className={styles.profilePic}>
            <img
              onError={handleBrokenImage}
              src={enterpriseImage}
              alt="company-logo"
            />
            {isOwner
              ? (
                <>
                  <div className="library-shaw-icon-new">
                    <i className="icon-new-Edit1" />
                  </div>
                  <label htmlFor="file">
                    <input
                      id="file"
                      accept="image/png, image/jpeg"
                      type="file"
                      onChange={uploadImage}
                    />
                  </label>
                </>
              ) : null}
          </div>
        </div>
        {
          isEdit.name
            ? (
              <input
                type="text"
                id="company"
                name="company"
                className={updatedCompanyInfo?.name && (!errors.name
                  ? styles.valid : styles.invalid)}
                value={updatedCompanyInfo?.name}
                placeholder="Enter company name"
                autoComplete="organization"
                onChange={handleInputChange}
              />
            )
            : <p className={styles.setting}>{updatedCompanyInfo?.name || '--'}</p>
        }
        {isOwner && (
          <div className={styles.ctaWrapper}>
            {isEdit.name
              ? (
                <div>
                  <button
                    id="admin-company-save-company-name"
                    className={styles.save}
                    disabled={loader.name}
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    id="admin-company-cancel-company-name-changes"
                    type="button"
                    onClick={() => toggleEdit('name', false, true)}
                    className={styles.buttonCancel}
                  >
                    Cancel
                  </button>
                  {loader.name && <Spin className={styles.loader} />}
                </div>
              )
              : (
                <button
                  id="admin-company-edit-company-name"
                  type="button"
                  onClick={() => toggleEdit('name', true)}
                >
                  {' '}
                  Edit

                </button>
              )}
          </div>
        )}
        <input className={styles.hideInput} type="email" name="email" id="email" value={profileInfo?.email} />
      </form>
      <form className={styles.companyProfileSection} onSubmit={updateInfo}>
        <div className={styles.heading}>
          <p>Company&apos;s profile</p>
          {isOwner && (
            <div className={styles.ctaWrapper}>
              {isEdit.profile
                ? (
                  <div>
                    <button
                      id="admin-company-save-changes-company-profile"
                      className={styles.save}
                      disabled={loader.profile}
                      type="submit"
                    >
                      Save changes
                    </button>
                    <button
                      id="admin-company-cancel-company-profile-changes"
                      type="button"
                      onClick={() => toggleEdit('profile', false, true)}
                      className={styles.buttonCancel}
                    >
                      Cancel
                    </button>
                    {loader.profile && <Spin className={styles.loader} />}
                  </div>
                )
                : (
                  <button
                    id="admin-company-edit-company-profile"
                    type="button"
                    onClick={() => toggleEdit('profile', true)}
                  >
                    Edit
                  </button>
                )}
            </div>
          )}
        </div>
        <div className={styles.profileContainer}>
          <div>
            <span className={styles.titleName}>Company Size</span>
            {isEdit.profile
              ? (
                <Select
                  id="numberOfEmployees"
                  defaultValue={updatedCompanyInfo?.numberOfEmployees || ''}
                  onChange={changeCompanySize}
                >
                  <Option value="" disabled selected>
                    Select company size
                  </Option>
                  <Option value="1-50">
                    1-50
                  </Option>
                  <Option value="51-500">
                    51-500
                  </Option>
                  <Option value="501-1000">
                    501-1000
                  </Option>
                  <Option value="1001-5000">
                    1001-5000
                  </Option>
                  <Option value="5000+">
                    5000+
                  </Option>
                </Select>
              )
              : (
                <p className={styles.titleValue}>
                  {updatedCompanyInfo?.numberOfEmployees || '--'}
                </p>
              )}
          </div>
          <div>
            <span className={styles.titleName}>Company Location</span>

            {isEdit.profile
              ? (
                <Select defaultValue={updatedCompanyInfo?.location || 'us'} onChange={changeCountry}>
                  {allCountries.map((country) => (
                    <Option key={country.iso2} value={country.iso2 || ''}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              )
              : (

                <p className={styles.titleValue}>
                  {currentCountry || '--'}
                </p>
              )}
          </div>
          <div>
            <span className={styles.titleName}>Phone number</span>
            {isEdit.profile
              ? (
                <IntlTelInput
                  containerClassName="intl-tel-input"
                  inputClassName={`tel-input ${updatedUserInfo.phoneNumber && (!errors.phoneNumber
                    ? styles.valid : styles.invalid)}`}
                  name="phoneNumber"
                  id="tel"
                  defaultCountry="us"
                  autoPlaceholder
                  allowExtensions
                  autoHideDialCode={false}
                  nationalMode={false}
                  autoFormat={false}
                  formatOnInit
                  onPhoneNumberChange={phoneNumberChanged}
                  autoComplete="off"
                  defaultValue={updatedUserInfo.phoneNumber || ''}
                />
              )
              : (
                <p className={styles.titleValue}>
                  {updatedUserInfo?.phoneNumber || '--'}
                </p>
              )}
          </div>
          <div>
            <span className={styles.titleName}>Job Title</span>
            {isEdit.profile
              ? (
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  // eslint-disable-next-line no-nested-ternary
                  className={updatedUserInfo?.designation ? (!errors.designation
                    ? styles.valid : styles.invalid) : ''}
                  value={updatedUserInfo?.designation || ''}
                  placeholder="Your job title"
                  onChange={handleInputChange}
                />
              )
              : (
                <p className={styles.titleValue}>
                  {updatedUserInfo?.designation || '--'}
                </p>
              )}
          </div>
        </div>
        <div className={styles.hiddenElements}>
          <input type="text" name="jobtitle" id="jobtitle" value={updatedUserInfo?.designation} />
          <input type="text" name="mobilephone" id="mobilephone" value={updatedUserInfo?.phoneNumber} />
          <input type="text" name="country" id="country" value={updatedCompanyInfo?.location} />
          <input type="text" name="numemployees" id="numemployees" value={updatedCompanyInfo?.numberOfEmployees} />
          <input type="email" name="email" id="email" value={profileInfo?.email} />
        </div>
      </form>
    </div>
  );
};

export default CompanyProfile;
