import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Link } from 'gatsby';
import AdminContext from '../../context/Admin/AdminContext';
import {
  getCurrentPlanItem, getPeriodText, getSubscriptionStatus, hasInviteLimits,
  isEligibleToManageBilling, isOnCustomPlan, isOnFreeTrialPlan,
} from '../../services/utils';
import CompanyProfile from './CompanyProfile';
import HeaderComponent from './Header';
import SidebarComponent from './Sidebar';
import * as styles from '../../styles/admin/Company.module.scss';
import { EnterpriseSubscriptionStatus } from '../../models';
import { NavigationRoute } from './Services/NavigationService';

type Props = {
}

const CompanyDetailsView: React.FC<Props> = () => {
  const { companyInfo, profileInfo } = useContext(AdminContext);
  const subscription = companyInfo?.subscription;

  const subscriptionExpiry = dayjs(subscription?.currentTermEnd).format('DD MMMM YYYY');

  const subscriptionStatus = getSubscriptionStatus(subscription) || '--';

  const subscriptionInfo = getCurrentPlanItem(subscription);

  const totalEnterpriseLicense = subscriptionInfo?.quantity - subscriptionInfo?.freeQuantity;

  const planInfo = subscriptionInfo?.name ?? '--';
  const currentlyOnCustomPlan = isOnCustomPlan(subscription);

  const billingPeriodText = (subscription?.status === EnterpriseSubscriptionStatus.ACTIVE)
    && (!currentlyOnCustomPlan)
    && getPeriodText(subscription.billingPeriod, subscription.billingPeriodUnit);

  const nextBillingText = subscription?.nextBillingAt
    && (!currentlyOnCustomPlan)
    && dayjs(subscription.nextBillingAt).format('DD MMMM YYYY');

  const invitesLimited = hasInviteLimits(subscription);

  const showBillingLink = isEligibleToManageBilling(subscription, profileInfo?.roles);

  const billingText = isOnFreeTrialPlan(subscription) ? 'Upgrade' : 'Manage Billing';

  return (
    <div className={styles.companyPageWrapper}>
      <HeaderComponent />
      <div style={{ display: 'flex', height: '100vh' }}>
        <SidebarComponent />
        <div className={styles.outerWrapper}>
          <CompanyProfile />
          <p className={styles.billingTitle}>Your Plan</p>
          <div className={`${styles.profileContainer} ${styles.billingSection}`}>
            {invitesLimited && (
              <div>
                <span className={styles.titleName}>Licenses Purchased</span>
                <p className={styles.titleValue}>{totalEnterpriseLicense}</p>
              </div>
            )}
            <div>
              <span className={styles.titleName}>Pricing</span>
              <p className={styles.titleValue}>
                {planInfo}
                {' '}
                {
                  billingPeriodText
                  && (
                    <span className={styles.date}>
                      {`(Billed every ${billingPeriodText})`}
                    </span>
                  )
                }
              </p>
            </div>
            <div>
              <span className={styles.titleName}>Status</span>
              <p className={styles.titleValue}>
                {subscriptionStatus}
                {' '}
                {
                  subscription?.status === EnterpriseSubscriptionStatus.NON_RENEWING
                  && (
                    <span className={styles.date}>
                      {`(Expires on ${subscriptionExpiry})`}
                    </span>
                  )
                }
                {
                  nextBillingText
                  && (
                    <span className={styles.date}>
                      {`(Next billing on ${nextBillingText})`}
                    </span>
                  )
                }
              </p>
              {
                showBillingLink
                && (
                  <Link to={NavigationRoute.BILLING_PAGE}>
                    <span className={styles.billingCTA}>{billingText}</span>
                  </Link>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsView;
