// extracted by mini-css-extract-plugin
export var companyPageWrapper = "Company-module--companyPageWrapper--3YHZh";
export var outerWrapper = "Company-module--outerWrapper--3f_PT";
export var billingTitle = "Company-module--billingTitle--3XAcy";
export var profileContainer = "Company-module--profileContainer--13YUD";
export var titleName = "Company-module--titleName--1byhd";
export var titleValue = "Company-module--titleValue--3jIxI";
export var date = "Company-module--date--2m0_i";
export var upgradeCta = "Company-module--upgradeCta--3L5Ri";
export var valid = "Company-module--valid--239dY";
export var invalid = "Company-module--invalid--PdEvK";
export var billingSection = "Company-module--billingSection--mMlTQ";
export var billingCTA = "Company-module--billingCTA--2WTPM";