import React from 'react';
import Authenticate from '../../../components/Identity/Authenticate';
import { AdminProvider } from '../../../context/Admin/AdminContext';
import CompanyDetailsView from '../../../components/Admin/CompanyDetailsView';
import AuthorizationWrapper from '../../../components/Admin/AuthorizationWrapper';

const CompanyPage = () => (
  <AdminProvider>
    <AuthorizationWrapper>
      <CompanyDetailsView />
    </AuthorizationWrapper>
  </AdminProvider>
);

export default Authenticate(CompanyPage);
