// extracted by mini-css-extract-plugin
export var profileUpdateContainer = "UpdateCompanyProfile-module--profileUpdateContainer--1hHR4";
export var companyWrapper = "UpdateCompanyProfile-module--companyWrapper--3q5r9";
export var setting = "UpdateCompanyProfile-module--setting--2JNuE";
export var userProfile = "UpdateCompanyProfile-module--userProfile--1EVyY";
export var profilePicLoader = "UpdateCompanyProfile-module--profilePicLoader--2pFWC";
export var profilePic = "UpdateCompanyProfile-module--profilePic--2H-kt";
export var hideInput = "UpdateCompanyProfile-module--hideInput--1dpJe";
export var ctaWrapper = "UpdateCompanyProfile-module--ctaWrapper--1gTkS";
export var save = "UpdateCompanyProfile-module--save--2zBa8";
export var buttonCancel = "UpdateCompanyProfile-module--buttonCancel--3AJr1";
export var companyProfileSection = "UpdateCompanyProfile-module--companyProfileSection--ARaYZ";
export var heading = "UpdateCompanyProfile-module--heading--2ge48";
export var profileContainer = "UpdateCompanyProfile-module--profileContainer--3w3LV";
export var titleName = "UpdateCompanyProfile-module--titleName--3l2wd";
export var titleValue = "UpdateCompanyProfile-module--titleValue--3_bWC";
export var date = "UpdateCompanyProfile-module--date--1CIUp";
export var hiddenElements = "UpdateCompanyProfile-module--hiddenElements--sVa3i";
export var valid = "UpdateCompanyProfile-module--valid--14DDM";
export var invalid = "UpdateCompanyProfile-module--invalid--3BMm_";